<script setup lang="ts">
// icons
import { faGlobe, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { faMapMarked, faChevronDown } from '@fortawesome/pro-regular-svg-icons';

// components
import CiTileImageSlider from '@/components/tiles/snippets/CiTileImageSlider.vue';
import CiAvailabilityInfo from '@/components/tiles/snippets/CiAvailabilityInfo.vue';
import CiLoadMore from '@/components/tiles/snippets/CiLoadMore.vue';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiCampsiteTileAsProps {
  allowSponsored?: boolean;
  campsite: any;
  flexAvailabilities?: any[];
  loading?: boolean;
  ranking?: number | null;
  showBorder?: boolean;
  showLocationLink?: boolean;
  showNoAvailabilityInfo?: boolean;
  snowplowContext?: any;
}
const props = withDefaults(defineProps<CiCampsiteTileAsProps>(), {
  allowSponsored: true,
  flexAvailabilities: () => [],
  loading: false,
  ranking: null,
  showBorder: true,
  showLocationLink: true,
  showNoAvailabilityInfo: false,
  snowplowContext: () => {},
});

const emit = defineEmits<{
  'location-clicked': [];
  'show-flex': [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const route = useRoute();
const { $gettext, $pgettext, $ngettext } = useGettext();
const { getFormattedPrice } = usePriceFormatter();
const { i18nNoExactAvailabilities } = useI18nSearchAvailabilities();
const { isFavorite, toggleFavorite } = useFavorites({ campsite: props.campsite });
const { mapRating } = useRating();
const { trackAdClick } = useTracking();
const { visibilityProducts } = useVisibilityProducts();
const {
  contexts,
  displayPrice,
  flexLoading,
  flexVisibleCount,
  getDetailQueryParams,
  getFormattedDate,
  getHighlightActivity,
  getHighlightTargetGroup,
  getNumberOfNights,
  getTopRatedFeature,
  getTopRatedForLocation,
  hasExactAccoAvailabilities,
  hasExactAvailabilities,
  hasExactPitchAvailabilities,
  hasFlexAvailabilities,
  hasIdealActivity,
  hasPpc,
  hasTopFeature,
  hasTopLocation,
  hasTopTargetGroup,
  hideFlexAvailabilities,
  i18nNoAvailabilityInfo,
  impressionId,
  isVisible,
  showFlexAvailabilities,
  showMoreFlexDates,
  tileTrackAdClickAndFollow,
  toggleFlexAvailabilities,
  websiteLink,
} = useCampsiteTile({
  campsite: () => props.campsite,
  flexAvailabilities: () => props.flexAvailabilities,
  snowplowContext: () => props.snowplowContext,
});

defineExpose({
  hideFlexAvailabilities,
});

/********************
 * REFS & VARS       *
 ********************/
const fanclubBenefits = computed(() => {
  return props.campsite.fanclub?.benefits || 0;
});

/********************
 * FUNCTIONS         *
 ********************/
function onMapMarkerClicked() {
  trackAdClick({
    bannerId: props.snowplowContext.bannerId,
    context: contexts.value,
    impressionId: impressionId.value,
    targetUrl: 'modal',
    zoneId: 'map',
  });

  emit('location-clicked');
}

function onToggleFlexAvailabilities() {
  emit('show-flex');
  toggleFlexAvailabilities();
}

function getCampingCardBasePrice(av: any) {
  return getFirstDiscountFromAvailability(av, [DISCOUNT_TYPE_CAMPING_CARD])?.price?.non_discounted_value || av.price;
}

function getAvailabilityWithoutCampingCard(availability, accoType) {
  return {
    acco_type: accoType,
    start_date: availability.start_date || route.query.from,
    end_date: availability.end_date || route.query.until,
    duration: availability.duration || getNumberOfNights(route.query.from, route.query.until),
    type: availability.type || null,
    currency: 'EUR',
    price: availability.price,
    basePrice: availability.price,
    stock: availability.stock || null,
    description: '',
    label: '',
    meta: {},
  };
}

function getAvailabilityWithOnlyCampingCard(availability, accoType) {
  const discount = getFirstDiscountFromAvailability(availability, [DISCOUNT_TYPE_CAMPING_CARD]);

  return {
    acco_type: accoType,
    start_date: availability.start_date || route.query.from,
    end_date: availability.end_date || route.query.until,
    duration: availability.duration || getNumberOfNights(route.query.from, route.query.until),
    type: DISCOUNT_TYPE_CAMPING_CARD,
    currency: discount.price.currency,
    price: discount.price.value,
    basePrice: getCampingCardBasePrice(availability),
    stock: availability.stock || null,
    description: '',
    label: '',
    meta: {},
  };
}
</script>

<template>
  <div class="rounded-b">
    <!-- base tile -->
    <div
      v-observe-visibility="{
        callback: isVisible,
        once: true,
        throttle: 500,
        intersection: {
          threshold: 0.7,
        },
      }"
      style="backface-visibility: hidden; transform: translate3d(0, 0, 0)"
      class="campsite-tile relative rounded-lg bg-white"
      :class="[
        { 'border border-gray-20': props.showBorder },
        visibilityProducts(props.campsite).hasConversionOptimization
          ? 'h-auto md:h-[14.4375] xl:h-[17.25rem]'
          : 'md:h-[10.875rem] xl:h-[12.9375rem]',
        {
          '!rounded-b-none':
            hasExactAvailabilities
            || hasFlexAvailabilities
            || (props.showNoAvailabilityInfo && (route.query.from || route.query.until)),
        },
      ]"
    >
      <div
        v-if="!props.loading"
        class="grid h-full grid-cols-3"
      >
        <!-- image, fav, sponsor mobile -->
        <div class="relative col-span-3 h-full md:col-span-1">
          <nuxt-link
            v-slot="{ href }"
            :to="
              localePath({
                name: RBN_CAMPSITE_DETAIL,
                params: { identifier: props.campsite.slug },
                query: getDetailQueryParams(),
              })
            "
            custom
          >
            <a
              class="block size-full"
              :href="href"
              @click="tileTrackAdClickAndFollow($event, 'campsite_image')"
            >
              <CiTileImageSlider
                class="overflow-hidden rounded-t-lg md:rounded-bl-lg md:rounded-tr-none"
                :class="[
                  {
                    '!rounded-b-none':
                      hasExactAvailabilities
                      || hasFlexAvailabilities
                      || (props.showNoAvailabilityInfo && (route.query.from || route.query.until)),
                  },
                ]"
                :campsite-name="props.campsite.name"
                :images="props.campsite.images"
                :desktop-use-square-images="visibilityProducts(props.campsite).hasConversionOptimization"
                :position="props.snowplowContext.position - 1"
              />
            </a>
          </nuxt-link>
          <!-- fav toggle -->
          <CiCampsiteFavorite
            class="absolute right-4 top-4 cursor-pointer"
            :active="isFavorite"
            :show-label="false"
            @click="toggleFavorite"
          />
          <div
            v-if="props.ranking"
            class="pointer-events-none absolute left-0 top-0 inline-flex -translate-y-1/2 translate-x-[-10px] rounded-full bg-warning px-[9px] py-0 text-sm font-bold text-white"
          >
            <span>{{ $gettext('Rank %{num}', { num: props.ranking }) }}</span>
          </div>
          <div
            v-if="fanclubBenefits > 0"
            class="pointer-events-none absolute left-0 inline-flex p-[6px]"
            :class="[props.ranking ? 'top-[10px]' : 'top-0']"
          >
            <img
              src="~/assets/svg/fanclub-logo-small.svg"
              loading="lazy"
              alt="Fanclub logo"
            >
          </div>
        </div>

        <!-- infos -->
        <div class="relative col-span-3 h-full md:col-span-2">
          <div class="ml-0 grid h-full grid-cols-3 p-2 md:!ml-2">
            <div class="col-span-3 md:col-span-2">
              <div class="flex h-full flex-col">
                <!-- sponsored -->
                <div
                  v-if="props.campsite.is_top && props.allowSponsored"
                  class="campsite-tile__sponsored right-10 top-0 mb-2 block text-[0.625rem] uppercase leading-[0.938rem] text-gray-light md:hidden"
                >
                  {{ $gettext('Sponsored') }}
                </div>
                <!-- location -->
                <ul class="order-2 m-0 list-none truncate p-0 leading-4 text-gray">
                  <li
                    v-if="props.campsite.federal_state && props.campsite.federal_state.name"
                    class="text-small-book inline after:p-1 after:content-['/'] last-of-type:after:content-['']"
                  >
                    {{ props.campsite.federal_state.name }}
                  </li>
                  <li
                    v-if="props.campsite.city"
                    class="text-small-book inline after:p-1 after:content-['/'] last-of-type:after:content-['']"
                  >
                    {{ props.campsite.city }}
                  </li>
                </ul>

                <!-- cp name -->
                <nuxt-link
                  class="order-3 m-0 line-clamp-2 text-xl font-medium leading-6 text-black hover:no-underline md:mr-2"
                  :to="
                    localePath({
                      name: RBN_CAMPSITE_DETAIL,
                      params: { identifier: props.campsite.slug },
                      query: getDetailQueryParams(),
                    })
                  "
                  @click.capture="tileTrackAdClickAndFollow($event, 'campsite_name')"
                >
                  {{ props.campsite.name }}
                </nuxt-link>

                <!-- rating / award -->
                <div class="order-4 mt-4 flex md:order-5 md:-ml-10 md:mt-auto">
                  <!-- rating -->
                  <div class="flex items-center">
                    <CiRatingRadial
                      :rating="props.campsite.rating_avg_overall"
                      class="chart-wrapper size-12"
                      font-class="font-bold text-sm"
                    />

                    <div
                      :class="{ 'campsite-tile__no-rating': !props.campsite.rating_count }"
                      class="my-auto ml-2 flex flex-col truncate text-xs leading-[18px]"
                    >
                      <span
                        v-if="props.campsite.rating_count"
                        class="font-bold uppercase"
                      >{{
                        mapRating(props.campsite.rating_avg_overall)
                      }}</span>
                      <span
                        v-if="props.campsite.rating_count"
                        class="rating-count"
                      >
                        {{
                          $ngettext('%{count} rating', '%{count} ratings', props.campsite.rating_count, {
                            count: props.campsite.rating_count,
                          })
                        }}
                      </span>
                      <span
                        v-if="!props.campsite.rating_count"
                        class="rating-count text-small-book uppercase text-dark"
                      >{{ $pgettext('Please keep short. Has little space.', 'No ratings yet') }}</span>
                    </div>
                  </div>

                  <!-- award -->
                  <div
                    v-if="props.campsite.ci_award"
                    class="ml-4 flex items-center text-sm"
                  >
                    <div class="rounded-ful w-12">
                      <img
                        class="size-10"
                        src="~/assets/svg/award.svg"
                        width="100%"
                        height="100%"
                        alt="Camping.info Award"
                      >
                    </div>
                    <div class="my-auto ml-0 flex flex-col">
                      <span class="text-xs font-bold uppercase">Award</span>
                      <span>{{ props.campsite.ci_award }}</span>
                    </div>
                  </div>
                </div>

                <!-- highlights -->
                <ul
                  v-if="visibilityProducts(props.campsite).hasConversionOptimization"
                  class="order-5 m-0 my-3 list-none truncate p-0 text-sm text-dark md:order-4 md:my-1 xl:mt-3"
                >
                  <li
                    v-if="hasTopLocation"
                    :title="getTopRatedForLocation(props.campsite.top_for_location.name)"
                    class="truncate p-0 py-1"
                  >
                    {{ getTopRatedForLocation(props.campsite.top_for_location.name) }}
                  </li>
                  <li
                    v-if="hasIdealActivity"
                    :title="getHighlightActivity(props.campsite.ideal_activity)"
                    class="truncate p-0"
                  >
                    {{ getHighlightActivity(props.campsite.ideal_activity) }}
                  </li>
                  <li
                    v-if="hasTopTargetGroup"
                    :title="getHighlightTargetGroup(props.campsite.top_target_group)"
                    class="truncate p-0 py-1"
                  >
                    {{ getHighlightTargetGroup(campsite.top_target_group) }}
                  </li>
                  <li
                    v-if="!hasTopLocation && hasTopFeature"
                    :title="getTopRatedFeature(props.campsite.top_feature)"
                    class="truncate p-0"
                  >
                    {{ getTopRatedFeature(props.campsite.top_feature) }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-span-3 md:col-span-1">
              <div class="flex h-full flex-col">
                <div
                  v-if="props.campsite.is_top && props.allowSponsored"
                  class="campsite-tile__sponsored mr-3 hidden text-right text-[0.625rem] uppercase leading-[0.938rem] text-gray-light md:block"
                >
                  {{ $gettext('Sponsored') }}
                </div>
                <div
                  v-if="props.showLocationLink || hasPpc"
                  class="m-0 flex flex-row items-end justify-center border-y border-gray-20 p-2 md:mb-auto md:mr-3 md:mt-3 md:flex-col md:border-0 md:p-0"
                >
                  <!-- location on map -->
                  <button
                    v-if="props.showLocationLink"
                    class="button button--link button--small mx-4 my-0 items-center text-[0.625rem] text-primary transition-none hover:no-underline md:mx-0"
                    @click="onMapMarkerClicked"
                  >
                    <CiAwesomeIcon
                      :icon="faMapMarked"
                      class="campsite-tile__map-icon fill-primary"
                      ratio="0.6"
                    />
                    <span class="uppercase">{{ $pgettext('Campsite Tile link to map modal', 'location') }}</span>
                  </button>

                  <!-- website -->
                  <a
                    v-if="hasPpc"
                    :href="websiteLink"
                    class="button button--link button--small mx-3 mt-0 items-center text-[0.625rem] text-primary transition-none hover:no-underline md:mx-0 md:mt-2"
                    referrerpolicy="unsafe-url"
                    target="_blank"
                    rel="noopener"
                  >
                    <CiAwesomeIcon
                      :icon="faGlobe"
                      class="campsite-tile__map-icon fill-primary"
                      ratio="0.6"
                    />
                    <span class="uppercase"> {{ $pgettext('Campsite Tile link to website', 'website') }}</span>
                  </a>
                </div>

                <!-- price & cc -->
                <nuxt-link
                  v-if="displayPrice"
                  :to="
                    localePath({
                      name: RBN_CAMPSITE_DETAIL,
                      params: { identifier: props.campsite.slug },
                      query: getDetailQueryParams(),
                    })
                  "
                  class="mr-3 mt-3 flex items-end justify-center text-black hover:no-underline md:mt-auto md:flex-col"
                  :class="{ 'mb-2': !hasExactAvailabilities }"
                  @click.capture="tileTrackAdClickAndFollow($event, 'campsite_price')"
                >
                  <!--
                    <ci-camping-card-logo
                      v-if="hasCampingCardDiscount"
                      class="mr-2 mr-md-0 my-auto"
                    ></ci-camping-card-logo>
                    -->
                  <span class="pr-2 font-medium md:pr-0">{{ getFormattedPrice(displayPrice) }} €</span>
                  <span
                    v-if="hasExactAvailabilities || hasFlexAvailabilities"
                    class="text-small-book mb-1 md:mb-0"
                  >{{
                    $pgettext('Campsite Tile price per stay', 'per stay')
                  }}</span>
                  <span
                    v-else
                    class="text-small-book mb-1 md:mb-0"
                  >{{
                    $pgettext('Campsite Tile price per night', 'per night')
                  }}</span>
                </nuxt-link>

                <!-- button link -->
                <nuxt-link
                  v-if="!hasExactAvailabilities && !hasFlexAvailabilities"
                  class="button button--primary order-3 w-full"
                  :class="{ 'mt-2': !displayPrice, 'mt-auto': !props.showLocationLink && !hasPpc && !displayPrice }"
                  :to="
                    localePath({
                      name: RBN_CAMPSITE_DETAIL,
                      params: { identifier: props.campsite.slug },
                      query: getDetailQueryParams(),
                    })
                  "
                  @click.capture="tileTrackAdClickAndFollow($event, 'button')"
                >
                  <span>{{ $pgettext('Campsite Tile to Detail page', 'Details') }}</span>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- loader -->
      <div
        v-else
        class="relative h-full"
      >
        <CiAnimatedLoadingIndicator class="absolute left-1/2 top-1/2 size-20 -translate-x-1/2 -translate-y-1/2" />
      </div>
    </div>

    <!-- no availability info -->
    <div
      v-if="
        !props.loading
          && !hasExactAvailabilities
          && !hasFlexAvailabilities
          && showNoAvailabilityInfo
          && (!!route.query.from || !!route.query.until)
      "
      class="flex items-center rounded-b-lg border-gray-20 bg-white px-4 py-2 text-xs text-info/90"
    >
      <CiAwesomeIcon
        class="mr-4 fill-info"
        :icon="faExclamationCircle"
        ratio="0.6"
      />
      {{ i18nNoAvailabilityInfo }}
    </div>

    <!-- no exact match info -->
    <div
      v-if="!props.loading && !hasExactAvailabilities && hasFlexAvailabilities"
      class="text-small-book flex items-center border border-gray-20 bg-white p-4 text-info/90"
    >
      <CiAwesomeIcon
        class="mr-4 fill-info"
        :icon="faExclamationCircle"
        ratio="0.6"
      />
      {{ i18nNoExactAvailabilities(getFormattedDate(route.query.from), getFormattedDate(route.query.until)) }}
    </div>

    <!-- exact dates -->
    <div
      v-if="!props.loading && hasExactAvailabilities"
      class="campsite-tile__availabilities border border-t-0 border-gray-20 bg-white"
      :class="[hasFlexAvailabilities ? 'rounded-b-none' : 'rounded-b-lg']"
    >
      <div class="campsite-tile__availability px-2 py-4">
        <!-- exact pitch -->
        <template v-if="hasExactPitchAvailabilities">
          <!-- duplicate availability without camping card -->
          <CiAvailabilityInfo
            :campsite="props.campsite"
            :availability="getAvailabilityWithoutCampingCard(props.campsite.availabilities.exact.pitch, 'pitch')"
            :snowplow-context="{ ...props.snowplowContext, zone: 'campsite_exact-date', contexts, impressionId }"
            primary
          />
          <CiAvailabilityInfo
            v-if="availabilityHasCampingCardDiscount(props.campsite.availabilities.exact.pitch)"
            :campsite="props.campsite"
            :availability="getAvailabilityWithOnlyCampingCard(props.campsite.availabilities.exact.pitch, 'pitch')"
            :snowplow-context="{ ...props.snowplowContext, zone: 'campsite_exact-date', contexts, impressionId }"
            primary
          />
        </template>

        <!-- exact acco -->
        <template v-if="hasExactAccoAvailabilities">
          <CiAvailabilityInfo
            :campsite="props.campsite"
            :availability="getAvailabilityWithoutCampingCard(props.campsite.availabilities.exact.acco, 'acco')"
            :snowplow-context="{ ...props.snowplowContext, zone: 'campsite_exact-date', contexts, impressionId }"
            primary
          />
        </template>
      </div>
    </div>

    <!-- flex toggle -->
    <div
      v-if="!props.loading && hasFlexAvailabilities"
      class="flex cursor-pointer select-none items-center justify-center border border-t-0 border-gray-20 bg-white p-2 text-sm text-primary"
      :class="[showFlexAvailabilities ? 'rounded-b-none' : 'rounded-b-lg']"
      @click="onToggleFlexAvailabilities"
    >
      <span
        v-if="!showFlexAvailabilities"
        key="show"
      >{{ $gettext('Show similar travel periods') }}</span>
      <span
        v-else
        key="hide"
      >{{ $gettext('Hide similar travel periods') }}</span>
      <div
        class="rotate-0 transition-transform"
        :style="[showFlexAvailabilities ? 'transform: rotateX(-180deg);' : '']"
      >
        <CiAwesomeIcon
          :icon="faChevronDown"
          class="ml-2 fill-primary"
          ratio="0.6"
        />
      </div>
    </div>

    <!-- flex dates -->
    <div
      v-if="hasFlexAvailabilities && showFlexAvailabilities"
      class="campsite-tile__availabilities campsite-tile__availabilities--flex rounded-b-lg border border-t-0 border-gray-20 bg-white"
    >
      <!-- flex loader -->
      <div
        v-if="flexLoading"
        class="relative flex items-center justify-center p-4 py-12"
      >
        <CiAnimatedLoadingIndicator class="size-8" />
      </div>
      <!-- flex availabilities -->
      <template v-if="!flexLoading && props.flexAvailabilities.length">
        <div
          v-for="(availability, idx) in props.flexAvailabilities.slice(0, flexVisibleCount)"
          :key="idx"
          class="border-b border-gray-20 px-2 py-4 last-of-type:border-0"
          :class="{ 'border-0': idx + 1 === flexVisibleCount }"
        >
          <!-- pitch -->
          <template v-if="availability.pitch">
            <!-- duplicate availability without camping card -->
            <CiAvailabilityInfo
              :campsite="props.campsite"
              :availability="
                getAvailabilityWithoutCampingCard(
                  {
                    ...availability.pitch,
                    start_date: availability.start_date,
                    end_date: availability.end_date,
                    duration: availability.duration,
                  },
                  'pitch',
                )
              "
              :snowplow-context="{ ...props.snowplowContext, zone: 'campsite_flex-date', contexts, impressionId }"
            />
            <CiAvailabilityInfo
              v-if="availabilityHasCampingCardDiscount(availability.pitch)"
              :campsite="props.campsite"
              :availability="
                getAvailabilityWithOnlyCampingCard(
                  {
                    ...availability.pitch,
                    start_date: availability.start_date,
                    end_date: availability.end_date,
                    duration: availability.duration,
                  },
                  'pitch',
                )
              "
              :snowplow-context="{ ...props.snowplowContext, zone: 'campsite_flex-date', contexts, impressionId }"
            />
          </template>

          <!-- acco -->
          <template v-if="availability.acco">
            <CiAvailabilityInfo
              :class="{ 'mt-4': availability.pitch }"
              :campsite="props.campsite"
              :availability="
                getAvailabilityWithoutCampingCard(
                  {
                    ...availability.acco,
                    start_date: availability.start_date,
                    end_date: availability.end_date,
                    duration: availability.duration,
                  },
                  'acco',
                )
              "
              :snowplow-context="{ ...props.snowplowContext, zone: 'campsite_flex-date', contexts, impressionId }"
            />
          </template>
        </div>

        <!-- flex load more -->
        <CiLoadMore
          :visible-count="flexVisibleCount"
          :count="props.flexAvailabilities.length"
          @show-more="showMoreFlexDates"
        />
      </template>
    </div>
  </div>
</template>

<style></style>
