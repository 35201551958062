import { equals } from 'ramda';

// utils
import dayjs from '@/utils/day';
import { getNumberOfNights } from '@/utils/dates';

// fixtures
import { highlightRatings, highlightActivity, highlightTargetGroup } from '@/locales/fixtures';

export default function useCampsiteTile({
  campsite: pCampsite = null,
  flexAvailabilities: pFlexAvailabilities = null,
  snowplowContext: pSnowplowContext = {},
}: {
  campsite?: MaybeRefOrGetter<any | null>;
  flexAvailabilities?: MaybeRefOrGetter<any | null>;
  snowplowContext?: MaybeRefOrGetter<any>;
}) {
  const campsite = toRef(pCampsite);
  const flexAvailabilities = toRef(pFlexAvailabilities);
  const snowplowContext = toRef(pSnowplowContext);

  /********************
   * COMPOSITIONS      *
   ********************/
  const backendStore = useBackendStore();
  const getRouteBaseName = useRouteBaseName();
  const localePath = useLocalePath();
  const route = useRoute();
  const router = useRouter();
  const runtimeConfig = useRuntimeConfig();
  const userStore = useUserStore();
  const { $gettext, $pgettext, interpolate } = useGettext();
  const { isFavorite } = useFavorites({ campsite: campsite.value });
  const { locale } = useI18n();
  const { trackAdImpression, impressionId, trackAdClickAndFollow, trackAdClick } = useTracking();
  const { visibilityProducts } = useVisibilityProducts();

  /********************
   * REFS & VARS       *
   ********************/
  const NEW_FLEX_ITEMS_TO_SHOW = 3;
  const availabilityPosition = ref(0);
  const contexts = shallowRef<any[]>([]);
  const flexLoading = ref(false);
  const flexVisibleCount = ref(3);
  const showFlexAvailabilities = ref(false);

  const hasImage = computed(() => {
    return campsite.value?.image || campsite.value?.images?.length > 0;
  });

  const hasPpc = computed(() => {
    return campsite.value?.purchases?.includes(256);
  });

  const hasTopLocation = computed(() => {
    return campsite.value?.top_for_location?.name;
  });

  const hasIdealActivity = computed(() => {
    return campsite.value?.ideal_activity;
  });

  const hasTopTargetGroup = computed(() => {
    return campsite.value?.top_target_group;
  });

  const hasTopFeature = computed(() => {
    return campsite.value?.top_feature;
  });

  const hasExactAvailabilities = computed(() => {
    return (campsite.value?.availabilities?.exact?.count || 0) > 0;
  });

  const hasExactPitchAvailabilities = computed(() => {
    return (campsite.value?.availabilities?.exact?.pitch?.count || 0) > 0;
  });

  const hasExactAccoAvailabilities = computed(() => {
    return (campsite.value?.availabilities?.exact?.acco?.count || 0) > 0;
  });

  const hasFlexAvailabilities = computed(() => {
    return campsite.value?.availabilities?.flex?.count > 0;
  });

  const hasCampingCardDiscount = computed(() => {
    if (!hasExactAvailabilities.value && !hasFlexAvailabilities.value) {
      return false;
    }

    const ccExactPitch = availabilityHasCampingCardDiscount(campsite.value?.availabilities.exact?.pitch);
    const ccExactAcco = availabilityHasCampingCardDiscount(campsite.value?.availabilities.exact?.acco);
    const ccFlexPitch = availabilityHasCampingCardDiscount(campsite.value?.availabilities.flex?.pitch);
    const ccFlexAcco = availabilityHasCampingCardDiscount(campsite.value?.availabilities.flex?.acco);

    return ccExactPitch || ccExactAcco || ccFlexPitch || ccFlexAcco;
  });

  const websiteLink = computed(() => {
    return `${backendStore.url}/click/?lang=${locale.value}&slug=${campsite.value?.slug}&link_identifier=${getRouteBaseName(route)}`;
  });

  const displayPrice = computed(() => {
    const prices = [];

    // exact prices
    if (hasExactAvailabilities.value) {
      const exactPitchPrice
        = campsite.value?.availabilities.exact.pitch && campsite.value?.availabilities.exact.pitch.price;
      const exactAccoPrice
        = campsite.value?.availabilities.exact.acco && campsite.value?.availabilities.exact.acco.price;
      if (exactPitchPrice) {
        prices.push(exactPitchPrice);
      }
      if (exactAccoPrice) {
        prices.push(exactAccoPrice);
      }

      const ccaDiscountsPitch = getDiscountsFromAvailability(
        campsite.value?.availabilities.exact.pitch,
        DISCOUNT_TYPE_CAMPING_CARD,
      );
      const ccaDiscountsAcco = getDiscountsFromAvailability(
        campsite.value?.availabilities.exact.acco,
        DISCOUNT_TYPE_CAMPING_CARD,
      );

      ccaDiscountsPitch.forEach((cca) => {
        prices.push(cca.price.value);
      });

      ccaDiscountsAcco.forEach((cca) => {
        prices.push(cca.price.value);
      });
    }

    // if no exact prices but flex as well
    if (!hasExactAvailabilities.value && hasFlexAvailabilities.value) {
      const flexPitchPrice
        = campsite.value?.availabilities.flex.pitch && campsite.value?.availabilities.flex.pitch.price;
      const flexAccoPrice = campsite.value?.availabilities.flex.acco && campsite.value?.availabilities.flex.acco.price;

      if (flexPitchPrice) {
        prices.push(flexPitchPrice);
      }

      if (flexAccoPrice) {
        prices.push(flexAccoPrice);
      }
    }

    return prices.length > 0 ? Math.min(...prices) : campsite.value?.price && campsite.value?.price.minimum_price;
  });

  const favoriteActive = computed(() => {
    if (!userStore.user.profile) {
      return false;
    }

    return isFavorite.value;
  });

  const isAS = computed(() => {
    return !!(route.query.from || route.query.until);
  });

  const i18nNoAvailabilityInfo = computed(() => {
    return $pgettext(
      'Campsite Tile - CP has no availability info',
      'We do not have availabilities for this campsite. You can request them directly from the campsite.',
    );
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function onCampsiteChange() {
    contexts.value = [];
    addBaseContexts();
    addHighlightContexts();
    addExactAvailabilityContexts();

    isVisible(true);
  }

  function onFlexAvailabilitiesChange(newValue, oldValue) {
    if (newValue) {
      flexLoading.value = false;

      if (!equals(newValue, oldValue) && newValue.length) {
        addFlexContextsAndTrack(0, NEW_FLEX_ITEMS_TO_SHOW);
      }
    }
  }

  function isVisible(isVisible: boolean) {
    if (isVisible) {
      const envURL = runtimeConfig.public.baseUrl.replace(/\/+$/, '');
      const resolvedRoute = router.resolve(
        localePath({ name: RBN_CAMPSITE_DETAIL, params: { identifier: campsite.value?.slug } }),
      );
      const targetUrl = `${envURL}${resolvedRoute.href}`;

      trackAdImpression({
        bannerId: snowplowContext.value?.bannerId,
        impressionId: impressionId.value,
        targetUrl,
        zoneId: snowplowContext.value?.zoneId,
        context: contexts.value,
      });
    }
  }

  function addBaseContexts() {
    const countExact = hasExactAvailabilities.value ? campsite.value?.availabilities.exact.count : 0;
    const countFlex = hasFlexAvailabilities.value ? campsite.value?.availabilities.flex.count : 0;

    contexts.value = [
      {
        schema: 'iglu:com.camparound/campinginfo_campsite/jsonschema/1-0-1',
        data: {
          booked_products: toRaw(campsite.value?.purchases) || null,
          camping_id: campsite.value?.camping_id,
          civ2_id: campsite.value?.id,
          slug: campsite.value?.slug,
        },
      },
      {
        schema: 'iglu:com.camparound/campinginfo_campsite_representation/jsonschema/1-0-2',
        data: {
          awards: campsite.value?.ci_award ? [campsite.value?.ci_award] : [],
          content: campsite.value?.image || null,
          count_exact: isAS.value ? countExact : null,
          count_flex: isAS.value ? countFlex : null,
          currency: 'EUR',
          favorite: favoriteActive.value,
          position: snowplowContext.value?.position || null,
          price: getCurrentInstance()?.type.__name === 'CiMapCampsiteTileMobile' ? null : displayPrice.value || null,
          rating: campsite.value?.rating_avg_overall || null,
        },
      },
    ];
  }

  function addExactAvailabilityContexts() {
    if (getCurrentInstance()?.type.__name === 'CiMapCampsiteTileMobile') {
      return;
    }

    if (hasExactAvailabilities.value) {
      if (campsite.value?.availabilities.exact.pitch && campsite.value?.availabilities.exact.pitch.count > 0) {
        availabilityPosition.value++;
        addContext({
          schema: 'iglu:com.camparound/campinginfo_campsite_availability/jsonschema/1-0-2',
          data: {
            accommodation_type: 'pitch',
            availability_type: 'exact',
            critical_stock: campsite.value?.availabilities.exact.pitch.stock <= 5,
            duration: getNumberOfNights(route.query.from, route.query.until),
            end_date: route.query.until,
            position: availabilityPosition.value,
            price: campsite.value?.availabilities.exact.pitch.price,
            start_date: route.query.from,
            stock: campsite.value?.availabilities.exact.pitch.stock,
          },
        });

        if (campsite.value?.availabilities.exact?.pitch?.discounts?.length) {
          campsite.value?.availabilities.exact.pitch.discounts.forEach((discount) => {
            addContext({
              schema: 'iglu:com.camparound/campinginfo_discount/jsonschema/1-0-0',
              data: {
                availability_id: `${route.query.from}_${route.query.until}_pitch`,
                price: {
                  value: discount.price?.value,
                  currency: discount.price?.currency,
                  non_discounted_value:
                    discount.price?.non_discounted_value || campsite.value?.availabilities.exact.pitch.price,
                },
                type: discount.type,
              },
            });
          });
        }
      }

      if (campsite.value?.availabilities.exact.acco && campsite.value?.availabilities.exact.acco.count > 0) {
        availabilityPosition.value++;
        addContext({
          schema: 'iglu:com.camparound/campinginfo_campsite_availability/jsonschema/1-0-2',
          data: {
            accommodation_type: 'acco',
            availability_type: 'exact',
            critical_stock: campsite.value?.availabilities.exact.acco.stock <= 5,
            duration: getNumberOfNights(route.query.from, route.query.until),
            end_date: route.query.until,
            position: availabilityPosition.value,
            price: campsite.value?.availabilities.exact.acco.price,
            start_date: route.query.from,
            stock: campsite.value?.availabilities.exact.acco.stock,
          },
        });

        if (campsite.value?.availabilities.exact?.acco?.discounts?.length) {
          campsite.value?.availabilities.exact.acco.discounts.forEach((discount) => {
            addContext({
              schema: 'iglu:com.camparound/campinginfo_discount/jsonschema/1-0-0',
              data: {
                availability_id: `${route.query.from}_${route.query.until}_acco`,
                price: {
                  value: discount.price?.value,
                  currency: discount.price?.currency,
                  non_discounted_value:
                    discount.price?.non_discounted_value || campsite.value?.availabilities.exact.acco.price,
                },
                type: discount.type,
              },
            });
          });
        }
      }
    }
  }

  function addHighlightContexts() {
    if (
      !visibilityProducts(campsite.value).hasConversionOptimization
      || getCurrentInstance()?.type.__name === 'CiMapCampsiteTileMobile'
    ) {
      return;
    }

    const highlightSchema = 'iglu:com.camparound/campinginfo_campsite_highlight/jsonschema/1-0-0';

    if (hasTopLocation.value) {
      // work-around because id needs to be a sting all the time
      const data = { ...campsite.value?.top_for_location };
      data.id = `${data.id}`;

      const highlightContext = {
        schema: 'iglu:com.camparound/campinginfo_location/jsonschema/1-0-3',
        data,
      };

      addContext(highlightContext);
    }

    if (hasIdealActivity.value) {
      const highlightContext = {
        schema: highlightSchema,
        data: {
          key: 'ideal_activity',
          value: campsite.value?.ideal_activity,
        },
      };
      addContext(highlightContext);
    }

    if (hasTopTargetGroup.value) {
      const highlightContext = {
        schema: highlightSchema,
        data: {
          key: 'top_target_group',
          value: campsite.value?.top_target_group,
        },
      };
      addContext(highlightContext);
    }

    if (!hasTopLocation.value && hasTopFeature.value) {
      const highlightContext = {
        schema: highlightSchema,
        data: {
          key: 'top_feature',
          value: campsite.value?.top_feature,
        },
      };
      addContext(highlightContext);
    }
  }

  function addContext(context: any) {
    contexts.value = [...contexts.value, context];
  }

  function getDetailQueryParams({ type, from, until } = { type: null, from: null, until: null }) {
    const query = { ...route.query };
    if (!query) {
      return {};
    }

    delete query.only_availabilities;
    delete query.offset;
    delete query.limit;
    delete query.lang;
    delete query.client;

    if (!type) {
      return query;
    } else {
      query.acco_type = type;
    }

    // delete query param
    if (query.q) {
      delete query.q;
    }

    if (from) {
      query.from = from;
    }

    if (until) {
      query.until = until;
    }

    return { ...query };
  }

  function getTopRatedForLocation(locationName) {
    const translation = $pgettext('keep short for campsite tile', 'Top rating for %{location}');
    return interpolate(translation, { location: locationName });
  }

  function getHighlightActivity(activity) {
    const translation = highlightActivity[activity];
    if (translation === undefined) {
      return null;
    }
    return $pgettext(translation.ctx, translation.key);
    // return translation;
  }

  function getHighlightTargetGroup(group) {
    const translation = highlightTargetGroup[group];
    if (translation === undefined) {
      return null;
    }
    return $pgettext(translation.ctx, translation.key);
    // return translation;
  }

  function getTopRatedFeature(feature) {
    const translation = highlightRatings[feature];
    if (translation === undefined) {
      return null;
    }
    return $pgettext(translation.ctx, translation.key);
    // return translation;
  }

  function getAccommodationName(type) {
    if (type === 'acco') {
      return $gettext('Rental accommodations');
    }
    if (type === 'pitch') {
      return $gettext('Pitches');
    }
  }

  function getAccommodationNameSingular(type) {
    if (type === 'acco') {
      return $gettext('Rental accommodation');
    }
    if (type === 'pitch') {
      return $gettext('Pitch');
    }
  }

  function getFormattedDate(date) {
    if (!date) {
      return;
    }
    return dayjs(date).format('DD.MM.YY');
  }

  function getDayForDate(date, format) {
    if (!date) {
      return;
    }
    return dayjs(date).format(format);
  }

  function toggleFlexAvailabilities() {
    flexLoading.value = true;
    showFlexAvailabilities.value = !showFlexAvailabilities.value;
  }

  function hideFlexAvailabilities() {
    showFlexAvailabilities.value = false;
  }

  function showMoreFlexDates() {
    flexVisibleCount.value = flexVisibleCount.value + NEW_FLEX_ITEMS_TO_SHOW;
    addFlexContextsAndTrack(flexVisibleCount.value - 3, flexVisibleCount.value);
  }

  function tileTrackAdClickAndFollow(event: MouseEvent, zone: string | null = null) {
    trackAdClickAndFollow(event, {
      bannerId: snowplowContext.value?.bannerId || null,
      impressionId: impressionId.value,
      zoneId: zone,
      context: contexts.value,
    });
  }

  function addFlexContextsAndTrack(begin, end) {
    flexAvailabilities?.value.slice(begin, end).forEach((element) => {
      if (element.pitch) {
        availabilityPosition.value++;
        addContext({
          schema: 'iglu:com.camparound/campinginfo_campsite_availability/jsonschema/1-0-2',
          data: {
            accommodation_type: 'pitch',
            availability_type: 'flex',
            critical_stock: element.pitch.stock <= 5,
            duration: element.duration,
            end_date: element.end_date,
            position: availabilityPosition.value,
            price: element.pitch.price,
            start_date: element.start_date,
            stock: element.pitch.stock,
          },
        });

        if (element.pitch?.discounts?.length) {
          element.pitch.discounts.forEach((discount) => {
            addContext({
              schema: 'iglu:com.camparound/campinginfo_discount/jsonschema/1-0-0',
              data: {
                availability_id: `${element.start_date}_${element.end_date}_pitch`,
                price: {
                  value: discount.price?.value,
                  currency: discount.price?.currency,
                  non_discounted_value: discount.price?.non_discounted_value || element.pitch.price,
                },
                type: discount.type,
              },
            });
          });
        }
      }

      if (element.acco) {
        availabilityPosition.value++;
        addContext({
          schema: 'iglu:com.camparound/campinginfo_campsite_availability/jsonschema/1-0-2',
          data: {
            accommodation_type: 'acco',
            availability_type: 'flex',
            critical_stock: element.acco.stock <= 5,
            duration: element.duration,
            end_date: element.end_date,
            position: availabilityPosition.value,
            price: element.acco.price,
            start_date: element.start_date,
            stock: element.acco.stock,
          },
        });

        if (element.acco?.discounts?.length) {
          element.acco.discounts.forEach((discount) => {
            addContext({
              schema: 'iglu:com.camparound/campinginfo_discount/jsonschema/1-0-0',
              data: {
                availability_id: `${element.start_date}_${element.end_date}_acco`,
                price: {
                  value: discount.price?.value,
                  currency: discount.price?.currency,
                  non_discounted_value: discount.price?.non_discounted_value || element.acco.price,
                },
                type: discount.type,
              },
            });
          });
        }
      }
    });

    // track
    trackAdClick({
      targetUrl: 'show-more',
      bannerId: snowplowContext.value?.bannerId,
      zoneId: `show-more-${end}`,
      impressionId: impressionId.value,
      context: contexts.value,
    });
  }

  /********************
   * WATCHER           *
   ********************/
  watch(campsite, onCampsiteChange, { immediate: false });
  watch(flexAvailabilities, onFlexAvailabilitiesChange, { immediate: true });

  /********************
   * HOOKS             *
   ********************/
  onMounted(() => {
    // for tracking
    addBaseContexts();
    addHighlightContexts();
    addExactAvailabilityContexts();
  });

  return {
    addExactAvailabilityContexts,
    addHighlightContexts,
    contexts,
    displayPrice,
    favoriteActive,
    flexLoading,
    flexVisibleCount,
    getAccommodationName,
    getAccommodationNameSingular,
    getDayForDate,
    getDetailQueryParams,
    getFormattedDate,
    getHighlightActivity,
    getHighlightTargetGroup,
    getNumberOfNights,
    getTopRatedFeature,
    getTopRatedForLocation,
    hasCampingCardDiscount,
    hasExactAccoAvailabilities,
    hasExactAvailabilities,
    hasExactPitchAvailabilities,
    hasFlexAvailabilities,
    hasIdealActivity,
    hasImage,
    hasPpc,
    hasTopFeature,
    hasTopLocation,
    hasTopTargetGroup,
    hideFlexAvailabilities,
    i18nNoAvailabilityInfo,
    impressionId,
    isAS,
    isVisible,
    showFlexAvailabilities,
    showMoreFlexDates,
    tileTrackAdClickAndFollow,
    toggleFlexAvailabilities,
    visibilityProducts,
    websiteLink,
  };
}
